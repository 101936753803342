import LocalizedStrings from 'react-localization';
import en from './locales/en';
import pt from './locales/pt_BR';
import ar from './locales/ar';
import de from './locales/de';
import it from './locales/it';
import es from './locales/es';
import fr from './locales/fr';
import hi_IN from './locales/hi_IN';
import ja from './locales/ja';
import ko from './locales/ko';
import ru from './locales/ru';
import tr from './locales/tr';
import zh_CN from './locales/zh_CN';
import zh_TW from './locales/zh_TW';
import id from './locales/id';
import ms from './locales/ms';
import nl from './locales/nl';
import pl from './locales/pl';
import sv from './locales/sv';
import th from './locales/th';
import vi from './locales/vi';

let strings = new LocalizedStrings({
  en,
  pt,
  ar,
  de,
  es,
  fr,
  hi: hi_IN,
  it,
  ja,
  ko,
  ru,
  tr,
  zh: zh_CN,
  'zh-CN': zh_CN,
  'zh-TW': zh_TW,
  id,
  ms,
  nl,
  pl,
  sv,
  th,
  vi,
});

export default strings;
