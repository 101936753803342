import React, { useState, useRef, RefObject } from 'react';
import classnames from 'classnames';
import { Button } from '../button';
import { DropMenu } from '../drop-menu';
import { ButtonIcon } from '../button-icon';
import { useOnClickOutside } from '../../lib/helpers/use-on-click-outside';

import { SidebarLibraryProps } from '../../types';

import styles from './sidebar-library.scss';

export const SidebarLibrary: React.FC<SidebarLibraryProps> = ({ className }) => {
  const ref: RefObject<any> = useRef();
  const [showDropUpload, setShowDropUpload] = useState(false);
  const [navMusicActive, setNavMusicActive] = useState('all');
  useOnClickOutside(ref, () => setShowDropUpload(false));

  const navFilterMusics = [
    { text: 'All music', icon: 'music', active: true, type: 'all' },
    { text: 'Split tracks', icon: 'split-track', active: false, type: 'split' },
    { text: 'Mastered tracks', icon: 'mastering', active: false, type: 'mastering' },
    { text: 'Denoiser', icon: 'ear', active: false, type: 'denoiser' },
  ];

  return (
    <div className={classnames(className, styles.sidebar)}>
      <div ref={ref} className={styles.containerUpload}>
        <Button
          text='Upload'
          className={styles.btnUpload}
          onHandleClick={() => setShowDropUpload(!showDropUpload)}
        />

        <DropMenu className={classnames(styles.dropMenu, { [styles.show]: showDropUpload })}>
          <ButtonIcon text='Split track' icon='split-track' className={styles.btnIcon} />
          <ButtonIcon text='Master track' icon='mastering' className={styles.btnIcon} />
          <ButtonIcon text='Denoiser track' icon='ear' className={styles.btnIcon} />
        </DropMenu>
      </div>

      <p className={styles.titleSection}>Music</p>
      <div className={styles.navSection}>
        {navFilterMusics.map((props) => (
          <ButtonIcon
            {...props}
            active={navMusicActive === props.type}
            onHandleClick={() => setNavMusicActive(props.type)}
          />
        ))}
      </div>
    </div>
  );
};
