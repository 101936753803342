import React, { useState, useRef, RefObject } from 'react';
import classnames from 'classnames';
import { Icon } from '../icon';
import { DropMenu } from '../drop-menu';
import { ButtonIcon } from '../button-icon';
import { useOnClickOutside } from '../../lib/helpers/use-on-click-outside';

import { TrackLineProps } from '../../types';

import styles from './track-line.scss';

export const TrackLine: React.FC<TrackLineProps> = ({ className, track }) => {
  const ref: RefObject<any> = useRef();
  const [showDropOptions, setShowDropOptions] = useState(false);
  useOnClickOutside(ref, () => setShowDropOptions(false));

  return (
    <div className={classnames(className, styles.trackLine)}>
      <Icon className={styles.iconPlay} icon='play' />

      <p className={styles.trackTitle}>{track.title}</p>

      <div
        ref={ref}
        className={styles.optionsContainer}
        onClick={() => setShowDropOptions(!showDropOptions)}
      >
        <Icon
          className={classnames(styles.btnOptions, { [styles.show]: showDropOptions })}
          icon='dots'
        />

        <DropMenu className={classnames(styles.dropOptions, { [styles.show]: showDropOptions })}>
          <ButtonIcon text='Download' icon='arrow-white' className={styles.btnIcon} />
          <ButtonIcon text='Delete' icon='trash' className={styles.btnIcon} />
        </DropMenu>
      </div>
    </div>
  );
};
