import React from 'react';
import classnames from 'classnames';
import { Icon } from '../icon';

import { ButtonIconProps } from '../../types';

import styles from './button-icon.scss';

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon,
  text,
  active = false,
  className,
  onHandleClick,
}) => {
  const handleClick = () => {
    if (onHandleClick) {
      onHandleClick();
    }
  };

  return (
    <div
      onClick={() => handleClick()}
      className={classnames(className, styles.buttonIcon, { [styles.active]: active })}
    >
      <Icon className={classnames(styles.icon, styles[icon])} icon={icon} />
      <p className={styles.label}>{text}</p>
    </div>
  );
};
