import React, { useState } from 'react';
import classnames from 'classnames';

import { IconProps } from '../../types';

import styles from './icon.scss';

export const Icon: React.FC<IconProps> = ({ className, icon, width, height }) => {
  const [show, setShow] = useState(true);
  const onError = () => {
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <img
      width={width}
      height={height}
      onError={onError}
      className={classnames(className, styles.icon)}
      src={`https://storage.googleapis.com/scala-web/assets/icons/${icon}.svg`}
    />
  );
};
