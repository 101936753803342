import React from 'react';
import classnames from 'classnames';

import { LoadingProps } from '../../types';

import styles from './loading.module.scss';

const SkeletonProfile: React.FC = () => (
  <div className={styles.profile}>
    <span className={styles.profileFill}></span>
    <span className={styles.profileAvatar}></span>
  </div>
);

const SkeletonTasks: React.FC = () => (
  <div className={styles.library}>
    <div className={styles.libraryTopLine}>
      <span className={styles.libraryXs}></span>
      <span className={styles.libraryMd}></span>
    </div>
    {[0, 1, 2, 3, 4].map((index) => (
      <div
        key={`loading-${index}`}
        className={classnames(styles.libraryLine, { [styles.useBackground]: index === 0 })}
      >
        <span className={styles.libraryXs}></span>
        <span className={styles.libraryMd}></span>
        <span className={styles.libraryLg}></span>
        <span className={styles.libraryMin}></span>
        <span className={styles.libraryMin}></span>
      </div>
    ))}
  </div>
);

const SkeletonTasksV2: React.FC = () => (
  <div className={styles.libraryV2}>
    <div className={styles.libraryV2TopLine}>
      <span className={styles.libraryV2TopLineMd}></span>
    </div>
    {[0, 1, 2, 3, 4].map((index) => (
      <div key={`loading-${index}`} className={styles.libraryV2Line}>
        <span className={styles.libraryV2LineXs}></span>
        <span className={styles.libraryV2LineLg}></span>
        <span className={styles.libraryV2LineMin}></span>
      </div>
    ))}
  </div>
);

const SkeletonSidebar: React.FC = () => (
  <div className={styles.sidebar}>
    <span className={styles.sidebarLg}></span>
    <span className={styles.sidebarXs}></span>
    {[0, 1, 2, 3, 4].map((index) => (
      <div key={`loading-${index}`} className={styles.sidebarLine}>
        <span className={styles.sidebarLineXs}></span>
        <span className={styles.sidebarLineLg}></span>
      </div>
    ))}
  </div>
);

const LoadingDefault: React.FC = () => (
  <div className={styles.loadingDefault}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export const Loading: React.FC<LoadingProps> = ({ className, type }) => (
  <div className={className}>
    {(() => {
      switch (type) {
        case 'skeleton-profile':
          return <SkeletonProfile />;
        case 'skeleton-tasks':
          return <SkeletonTasks />;
        case 'skeleton-tasks-v2':
          return <SkeletonTasksV2 />;
        case 'skeleton-sidebar':
          return <SkeletonSidebar />;
        default:
          return <LoadingDefault />;
      }
    })()}
  </div>
);
