import React, { useCallback, useState, useContext, useRef, RefObject } from 'react';
import { Context } from '../context';
import classnames from 'classnames';
import { useOnClickOutside } from '../../lib/helpers/use-on-click-outside';
import { UserAvatar } from '../user-avatar';
import strings from '../../data/localization';

import { NavUserProps } from '../../types';

import styles from './nav-user.module.scss';

export const NavUser: React.FC<NavUserProps> = ({ className }) => {
  const ref: RefObject<any> = useRef();
  const [hoverNav, setHoverNav] = useState(false);

  const { onNavigate, currentPathName, user } = useContext(Context);
  const [showNav, setShowNav] = useState(false);

  const isPremium = user?.subscription?.isPremium === true;

  const handleClickNavItem = useCallback(
    (link) => {
      setShowNav(false);
      onNavigate(link);
    },
    [onNavigate]
  );

  useOnClickOutside(ref, () => setShowNav(false));

  return (
    <div className={classnames(className, styles.navUser)}>
      <div
        className={classnames(styles.userInfo, { [styles.active]: showNav })}
        onMouseEnter={() => setHoverNav(true)}
        onMouseLeave={() => setHoverNav(false)}
        onClick={() => setShowNav(!showNav)}
      >
        <div className={styles.userInfoContainer}>
          <p className={styles.label}>{(user && user.name) || (user && user.email)}</p>
          {isPremium ? (
            <p className={styles.labelPremium}>{strings['account.labelPremium']}</p>
          ) : null}
        </div>
        <UserAvatar currentPathName={currentPathName} active={showNav || hoverNav} user={user} />
      </div>

      <ul ref={ref} className={classnames(styles.navContainer, { [styles.show]: showNav })}>
        <li onClick={() => handleClickNavItem('/settings')} className={styles.navItem}>
          <span className={styles.navLink}>{strings['account.settings']}</span>
        </li>
        {isPremium ? null : (
          <li onClick={() => handleClickNavItem('/pricing')} className={styles.navItem}>
            <span className={classnames(styles.navLink, styles.premium)}>
              {strings['account.premium']}
              <img
                className={styles.iconArrow}
                src='https://storage.googleapis.com/scala-web/assets/icons/arrow.svg'
              />
            </span>
          </li>
        )}
        <li
          onClick={() => handleClickNavItem('/logout')}
          className={classnames(styles.navItem, styles.divisor)}
        >
          <span className={styles.navLink}>
            <img
              className={styles.iconLogout}
              src='https://storage.googleapis.com/scala-web/assets/icons/logout.svg'
            />
            {strings['account.signOut']}
          </span>
        </li>
      </ul>
    </div>
  );
};
