import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Icon } from '../icon';

import { FormSearchProps } from '../../types';

import styles from './form-search.scss';

export const FormSearch: React.FC<FormSearchProps> = ({ placeholder, className, onSubmit }) => {
  const handleOnKeyDown = useCallback(
    (e: any) => {
      if (e.key === 'Enter' && onSubmit) {
        // should use ref to get input value
        onSubmit('get input value here');
      }
    },
    [onSubmit]
  );

  return (
    <div className={classnames(className, styles.formSearch)}>
      <Icon icon='search-gray' className={styles.icon} />
      <input
        type='text'
        className={styles.input}
        placeholder={placeholder}
        onKeyDown={handleOnKeyDown}
      />
    </div>
  );
};
