import React from 'react';
import classnames from 'classnames';
import { Icon } from '../icon';

import { ButtonProps } from '../../types';

import styles from './button.scss';

export const Button: React.FC<ButtonProps> = ({ text, className, onHandleClick }) => {
  const handleClick = () => {
    if (onHandleClick) {
      onHandleClick();
    }
  };

  return (
    <button onClick={() => handleClick()} className={classnames(className, styles.button)}>
      <Icon icon='upload-black' className={styles.icon} />
      <p className={styles.label}>{text}</p>
    </button>
  );
};
