import React from 'react';
import classnames from 'classnames';
import { UserAvatarProps } from '../../types';
import styles from './user-avatar.module.scss';

const getInitials = (name: string) =>
  name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .slice(0, 2);

const isActive = ({ pathname, active }: { pathname: string; active: boolean }) =>
  active || pathname === '/settings';

export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  active = false,
  currentPathName,
}) => {
  return (
    <div
      className={classnames(styles.avatar, {
        [styles.active]: isActive({ pathname: currentPathName, active }),
      })}
    >
      {(user && user.profilePictureUrl && (
        <img className={styles.image} src={user.profilePictureUrl} />
      )) ||
        (user && user.name && <div className={styles.initials}>{getInitials(user.name)}</div>) || (
          <img
            className={styles.icon}
            src='https://storage.googleapis.com/scala-web/assets/icons/user.svg'
          />
        )}
    </div>
  );
};
