import { useCallback, useEffect, useState } from 'react';
import { useAsyncRetry } from 'react-use';
import { axiosWithRetries as axios } from '../../../lib/helpers/axios';

const getUserData = async (apiUrl, auth) => {
  if (!auth) return null;

  const { data } = await axios.get(apiUrl, {
    headers: {
      Authorization: auth,
      'Content-Type': 'application/json',
    },
  });

  return {
    user: {
      ...data,
      subscription: {
        ...data.subscription,
        active: data.subscription.isPremium,
      },
    },
  };
};

export const useGetUserData = ({ apiUrl, auth }: { apiUrl: string; auth: string | null }): any => {
  const [silentRetrying, setSilentRetrying] = useState(false);

  const { retry, loading, value, error } = useAsyncRetry(async () => getUserData(apiUrl, auth), [
    apiUrl,
    auth,
  ]);

  const silentRetry = useCallback(() => {
    setSilentRetrying(true);
    retry();
  }, [retry]);

  useEffect(() => {
    if (loading === false && auth) {
      setSilentRetrying(false);
    }
  }, [loading, auth]);

  return {
    silentRetry,
    retry,
    loading: !silentRetrying && (loading || !auth),
    value,
    error,
  };
};
