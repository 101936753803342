import React from 'react';
import classnames from 'classnames';
import strings from '../../data/localization';

import { ErrorProps } from '../../types';

import styles from './error.module.scss';

export const Error: React.FC<ErrorProps> = ({ className, stateRetry, title, message }) => (
  <div className={classnames(className, styles.errorTemplate)}>
    <p className={styles.title}>{title}</p>
    <p className={styles.message}>{message}</p>
    {stateRetry ? (
      <button className={styles.btnRetry} onClick={() => stateRetry()}>
        {strings['page.btnRetry']}
      </button>
    ) : null}
  </div>
);
