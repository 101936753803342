import React, { useContext } from 'react';
import { Context } from '../context';
import { Nav } from '../nav';
import { NavUser } from '../nav-user';
import { Loading } from '../loading';
import { ErrorBar } from '../error-bar';
import strings from '../../data/localization';
import { HeaderBarProps } from '../../types';
import styles from './header-bar.module.scss';

export const HeaderBar: React.FC<HeaderBarProps> = ({
  items,
  loadingState = false,
  failState = false,
}) => {
  const { onNavigate } = useContext(Context);

  return (
    <header className={styles.headerBar}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <picture onClick={() => onNavigate('/')} className={styles.link}>
            <source
              srcSet='https://storage.googleapis.com/scala-web/assets/icons/moises-logo.svg'
              media='(min-width: 640px)'
            />
            <img
              className={styles.logoMoises}
              alt='Moises AI'
              src='https://storage.googleapis.com/scala-web/assets/icons/moises-acqua.svg'
            />
          </picture>
        </div>

        <Nav className={styles.nav} items={items} />

        {loadingState && failState === false ? (
          <Loading className={styles.loading} type='skeleton-profile' />
        ) : failState ? (
          <ErrorBar className={styles.loading} title={strings['header.state.loadingFailed']} />
        ) : (
          <NavUser className={styles.navUser} />
        )}
      </div>
    </header>
  );
};
