import axios from 'axios';
import axiosRetry from 'axios-retry';

const withRetries = () => {
  const axiosClient = axios.create();
  axiosRetry(axiosClient, { retries: 3 });
  return axiosClient;
};

export const axiosWithRetries = withRetries();
