import React, { useCallback, useState, useEffect } from 'react';
import { useGetUserData } from './hooks/use-get-user-data';
import { ContextProvider } from '../context';
import { Loading } from '../loading';
import { Error } from '../error';
import { HeaderBar } from '../header-bar';
import strings from '../../data/localization';
import { AppFrameProps } from '../../types';
import styles from './app-frame.module.scss';

export const AppFrame: React.FC<AppFrameProps> = ({
  apiUrl,
  auth,
  children,
  onNavigate,
  currentPathName,
  navItems = [],
  loadingTpl = null,
}) => {
  const { loading, value, error, retry, silentRetry } = useGetUserData({ apiUrl, auth });
  const hasError = !!error;

  return (
    <ContextProvider
      silentRetry={silentRetry}
      user={value?.user}
      onNavigate={onNavigate}
      currentPathName={currentPathName}
    >
      <HeaderBar items={navItems} failState={hasError} loadingState={loading} />
      {loading && (loadingTpl ? loadingTpl : <Loading className={styles.loading} />)}
      {!loading && hasError && (
        <Error
          className={styles.error}
          title={strings['page.errorTitle']}
          message={strings['page.errorLabel']}
          stateRetry={retry}
        />
      )}
      {!loading && !hasError && children}
    </ContextProvider>
  );
};
