import { useState, useEffect, useCallback } from 'react';

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = useCallback(() => {
    setIsMobile(global?.window?.innerWidth < 640);
  }, []);

  useEffect(() => {
    handleResize();

    global?.window?.addEventListener('resize', handleResize);

    return () => global?.window?.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return isMobile;
};
