import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Context } from '../context';
import classnames from 'classnames';
import { useIsMobile } from '../../lib/helpers/use-is-mobile';

import { NavProps } from '../../types';

import styles from './nav.module.scss';

export const Nav: React.FC<NavProps> = ({ className, items }) => {
  const isMobile = useIsMobile();
  const { onNavigate } = useContext(Context);
  const [showNav, setShowNav] = useState(!isMobile);

  useEffect(() => {
    setShowNav(!isMobile);
  }, [isMobile]);

  const handleClickNavItem = useCallback(
    (link) => {
      if (isMobile) {
        setShowNav(false);
      }

      onNavigate(link);
    },
    [isMobile, onNavigate]
  );

  return (
    <nav className={classnames(className, styles.nav)}>
      <img
        onClick={() => setShowNav(true)}
        className={styles.btnMenu}
        src='https://storage.googleapis.com/scala-web/assets/icons/menu.svg'
      />
      <img
        onClick={() => setShowNav(false)}
        className={classnames(styles.btnClose, { [styles.show]: showNav && isMobile })}
        src='https://storage.googleapis.com/scala-web/assets/icons/close.svg'
      />

      <ul className={classnames(styles.navContainer, { [styles.show]: showNav })}>
        {items.map((item, index) => (
          <li
            onClick={() => handleClickNavItem(item.link)}
            key={`nav-${index}`}
            className={styles.navItem}
          >
            <span className={classnames(styles.navLink, { [styles.active]: item.active })}>
              {item.label}
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
};
